import React from 'react'
import classNames from 'classnames/bind'

import styles from './Dropdown.module.css'
const cx = classNames.bind(styles)

const DropdownSection = ({
  className,
  children,
  title
}: {
  className?: string
  children?: React.ReactNode
  title?: string
}) => (
  <div className={cx(styles.dropdownSection, className, { hasTitle: Boolean(title) })}>
    {title && (
      <div className={styles.dropdownSectionTitle}>
        <div className="typography-nano"></div>
      </div>
    )}
    {children}
  </div>
)

export default DropdownSection
